import React from 'react';
import { Button, SxProps, Theme } from '@mui/material';

type SecondaryTextButtonProps = {
    children: React.ReactNode;
    onClick: () => void;
    isDisabled?: boolean;
    testId?: string;
    sx?: SxProps<Theme>;
};

export function SecondaryTextButton({
    children,
    onClick,
    isDisabled = false,
    testId = 'secondaryTextButton',
    sx = {},
}: SecondaryTextButtonProps) {
    return (
        <Button
            variant="text"
            onClick={onClick}
            disabled={isDisabled}
            data-testid={testId}
            sx={{
                color: 'grey.600',
                px: 3,
                py: 1,
                ...sx,
            }}
        >
            {children}
        </Button>
    );
}
