import {
    styled,
    Tooltip as MuiTooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import React from 'react';

interface SimpleTooltipProps {
    title: string | React.ReactElement;
    children: React.ReactElement;
}

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }}>
        {props.children}
    </MuiTooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.background.default,
        boxShadow: theme.shadows[1],
    },
}));

export function SimpleTooltip({ children, title }: SimpleTooltipProps) {
    return (
        <Tooltip title={<Typography variant="body2">{title}</Typography>} arrow>
            {children}
        </Tooltip>
    );
}
