export function DockIconSmall() {
    return (
        <svg
            data-testid="dockIconSmall"
            width="34"
            height="33"
            viewBox="0 0 34 33"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.23266 4.8309L3.63673 1.59787C3.75078 0.685032 4.52648 0 5.44616 0H5.44621H27.9625C31.3818 0 34.0274 2.99777 33.6032 6.39158L33.5973 6.43919L31.184 25.7507L31.1739 25.8313C30.7176 29.4816 27.6154 32.2207 23.9378 32.2207L15.9044 32.2024L14.3023 32.206L14.4995 30.5554L14.9041 27.3751L15.1054 25.7648H16.7043L21.5231 25.7729C22.4433 25.7729 23.2202 25.0885 23.3364 24.1753L23.3391 24.1545L25.3905 8.0515V7.93797C25.3905 7.13298 24.7389 6.48 23.9341 6.47859L4.63011 6.44407L3.03142 6.4412L3.23266 4.8309ZM0.011392 30.6082C-0.0948083 31.4578 0.554669 32.2024 1.41066 32.206H6.25115H7.86101L8.06662 30.5553L9.25938 20.9339L9.46 19.3236H12.6797H15.8885H17.5202L17.7078 17.7133L18.1158 14.492L18.3251 12.8824L16.7043 12.8823L3.83642 12.8818L2.22656 12.8817L2.02533 14.492L0.0129516 30.5957L0.011392 30.6082Z"
                fill="white"
            />
        </svg>
    );
}
