import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { PrimaryButton } from '../Buttons';
import { ExportModal } from './components/ExportModal';
import { CSVDataFormat, FetchNextPage } from './types';

type DownloadProps<T> = {
    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    data: CSVDataFormat;
    emptyData: CSVDataFormat;
    isError: boolean;
    isFetching: boolean;
    isDisabled?: boolean;
    hasNextPage: boolean | undefined;
    fileNamePrefix: string;
    fetchNextPage: FetchNextPage<T>;
    onApplyFilterClick: () => void;
};

export function Download<T>({
    isOpen,
    setOpen,
    setClose,
    isError,
    fetchNextPage,
    isFetching,
    data,
    emptyData,
    fileNamePrefix,
    hasNextPage,
    isDisabled = false,
    onApplyFilterClick,
}: DownloadProps<T>) {
    return (
        <>
            <PrimaryButton
                size="small"
                onClick={setOpen}
                isDisabled={isDisabled}
                testId="downloadButton"
                sx={{ maxHeight: '40px', margin: 'auto 0' }}
            >
                <FileDownloadOutlinedIcon />
            </PrimaryButton>

            <ExportModal
                data={data}
                emptyData={emptyData}
                isOpen={isOpen}
                isError={isError}
                hasNextPage={hasNextPage}
                isFetching={isFetching}
                fileNamePrefix={fileNamePrefix}
                fetchNextPage={fetchNextPage}
                onApplyFilterClick={onApplyFilterClick}
                setClose={setClose}
            />
        </>
    );
}
