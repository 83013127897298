import React from 'react';
import { InputAdornment, InputProps, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

type SearchBoxProps = {
    setLastSearchValue: (s: string) => void;
    label: string;
    initValue?: string;
    inputProps?: InputProps;
    isDisable?: boolean;
};

/**
 * To use it correctly you have to pass searchOnKeydown function and set last search value for example
 *
 * ```typescript
 * const [lastSearchValue, setLastSearchValue] = React.useState('');
 *
 * const searchOnEnterPress = (
 *       e: React.KeyboardEvent<HTMLDivElement>,
 *       searchValue: string
 *   ) => {
 *       if (e.key === 'Enter') {
 *           setLastSearchValue(searchValue);
 *       }
 *   };
 *
 */
export function SearchBox({
    setLastSearchValue,
    label,
    initValue = '',
    inputProps,
    isDisable = false,
}: SearchBoxProps) {
    const [searchValue, setSearchValue] = React.useState(initValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const searchOnEnterPress = (e: React.KeyboardEvent, value: string) => {
        if (e.key === 'Enter') {
            setLastSearchValue(value);
        }
    };

    const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) =>
        searchOnEnterPress(e, searchValue);

    const clearInput = () => {
        setSearchValue('');
        setLastSearchValue('');
    };

    return (
        <TextField
            id="search"
            label={label}
            value={searchValue}
            onChange={handleChange}
            onKeyDown={onKeyDownHandler}
            inputProps={{ 'data-testid': `searchBoxInput` }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        sx={{ color: ({ palette }) => palette.grey[400] }}
                        position="start"
                    >
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: !!searchValue && (
                    <InputAdornment
                        onClick={clearInput}
                        position="end"
                        sx={{
                            cursor: 'pointer',
                            color: ({ palette }) => palette.grey[400],
                        }}
                    >
                        <CancelOutlinedIcon />
                    </InputAdornment>
                ),
                ...inputProps,
            }}
            variant="outlined"
            fullWidth
            sx={{
                width: { md: '350px', lg: '390px', xxl: '430px' },
            }}
            disabled={isDisable}
        />
    );
}
