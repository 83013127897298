import React from 'react';
import { IconButton, Popper, List, Grow, Paper, ClickAwayListener, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export type ActionMenuItemProps = {
    content: string | React.ReactNode;
    id: string;
    isHidden?: boolean;
};

type ActionsMenuProps = {
    menuItems: ActionMenuItemProps[];
};

export function ActionsMenu({ menuItems }: ActionsMenuProps) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef?.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <IconButton
                ref={anchorRef}
                id="actions-button"
                data-testid="actionsDotsButton"
                aria-controls={open ? 'actions-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MoreVertIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="left"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List id="actions-menu" aria-labelledby="actions-button">
                                    {menuItems
                                        .filter(({ isHidden = false }) => !isHidden)
                                        .map(({ content, id }) => (
                                            <Box key={id}>{content}</Box>
                                        ))}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
