export default {
    FILTER: 'Filter',
    APPLY: 'Apply',
    END_DATE_AFTER_NOW: 'Future dates cannot be used for filtering.',
    START_DATE_BEFORE_MIN: 'Date is too old. The maximum allowed start date is 1987-05-03 00:00.',
    START_DATE_AFTER_END_DATE: 'End date cannot be before start date.',
    FROM: 'From',
    TO: 'To',
    DROP_DOWN_DATE_RANGE: 'Time range',
    DROP_DOWN_CUSTOM_DATE_RANGE: 'Custom range',
    ALL_TIME_DATE: 'All time',
    DROP_DOWN_LAST_24HOURS: 'Last 24 hours',
    DROP_DOWN_LAST_WEEK: 'Last week',
    DROP_DOWN_LAST_2WEEKS: 'Last 2 weeks',
    DROP_DOWN_LAST_MONTH: 'Last month',
    DROP_DOWN_LAST_3MONTHS: 'Last 3 months',
    DROP_DOWN_LAST_6MONTHS: 'Last 6 months',
    COPY_CURL: 'Copy cURL request',
};
