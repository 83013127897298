import { Helmet } from 'react-helmet-async';

export function Head({ title }: { title: string }) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <link rel="canonical" href="https://www.dock.financial/" />
        </Helmet>
    );
}
