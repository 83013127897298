import { Typography } from '@mui/material';

interface AccordionTitleProps {
    content: string;
}

export function AccordionTitle({ content }: AccordionTitleProps) {
    return (
        <Typography sx={{ color: (theme) => theme.palette.grey[600], mb: '4px' }}>
            {content}
        </Typography>
    );
}
