import React from 'react';
import {
    Box,
    Modal as MuiModal,
    Theme,
    SxProps,
    IconButton,
    Typography,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalPaperStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.default',
    maxWidth: '80%',
    borderRadius: '8px',
    overflowY: 'auto',
};

type ModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    modalAriaTitle: string;
    modalAriaDescription?: string;
    isShowCloseIcon?: boolean;
    children: React.ReactNode;
    title?: string;
    disableBackdropClick?: boolean;
    testId?: string;
    modalStyles?: SxProps<Theme>;
    contentStyles?: SxProps<Theme>;
};

export function Modal({
    handleClose,
    isOpen,
    modalAriaDescription,
    modalAriaTitle,
    isShowCloseIcon = true,
    children,
    title,
    disableBackdropClick = true,
    testId,
    modalStyles = {},
    contentStyles = {},
}: ModalProps) {
    return (
        <MuiModal
            open={isOpen}
            onClose={(_, reason) => {
                if (disableBackdropClick && reason !== 'backdropClick') {
                    handleClose();
                }
            }}
            aria-labelledby={modalAriaTitle}
            aria-describedby={modalAriaDescription}
            slotProps={{
                backdrop: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    'data-testid': 'modalBackdrop',
                },
            }}
        >
            <Box sx={modalPaperStyles}>
                <Box sx={modalStyles}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: title ? 'space-between' : 'flex-end',
                            alignItems: 'center',
                            padding: '24px 24px 8px 24px',
                        }}
                    >
                        {title && (
                            <Typography data-testid="modalTitle" component="h3" variant="h3">
                                {title}
                            </Typography>
                        )}

                        {isShowCloseIcon && (
                            <IconButton
                                onClick={handleClose}
                                aria-label="close"
                                data-testid="closeModal"
                            >
                                <CloseIcon sx={{ fontSize: 'h2.fontSize' }} />
                            </IconButton>
                        )}
                    </Box>

                    {title && <Divider />}

                    <Box
                        sx={{
                            padding: '24px 24px 24px 24px',
                            ...contentStyles,
                        }}
                        data-testid={testId}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
        </MuiModal>
    );
}
