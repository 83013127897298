import { Modal } from '../../Modal';
import { InitialState } from './InitialState';
import { ProgressState } from './ProgressState';
import { SuccessState } from './SuccessState';
import { ErrorState } from './ErrorState';
import { CSVDataFormat, FetchNextPage } from '../types';
import { useExportModal } from './useExportModal';
import downloadLang from '../../lang/download';

type Props<T> = {
    isOpen: boolean;
    hasNextPage: boolean | undefined;
    setClose: () => void;
    fetchNextPage: FetchNextPage<T>;
    isError: boolean;
    isFetching: boolean;
    data: CSVDataFormat;
    emptyData: CSVDataFormat;
    fileNamePrefix: string;
    onApplyFilterClick: () => void;
};

export function ExportModal<T>({
    isOpen,
    setClose,
    isError,
    fetchNextPage,
    isFetching,
    data,
    emptyData,
    fileNamePrefix,
    hasNextPage,
    onApplyFilterClick,
}: Props<T>) {
    const {
        state,
        isShowCloseIcon,
        handleTryAgainClick,
        handleDownloadClick,
        handleProceedExport,
    } = useExportModal({
        setClose,
        isError,
        fetchNextPage,
        isFetching,
        data,
        emptyData,
        fileNamePrefix,
        hasNextPage,
    });

    return (
        <Modal
            modalAriaTitle={downloadLang.DATA_EXPORT}
            isOpen={isOpen}
            handleClose={setClose}
            modalStyles={{ width: '460px' }}
            contentStyles={{ paddingTop: '0' }}
            isShowCloseIcon={isShowCloseIcon}
            testId="dataExportModal"
        >
            {state === 'initial' && (
                <InitialState onLeftClick={onApplyFilterClick} onRightClick={handleProceedExport} />
            )}
            {state === 'progress' && <ProgressState length={data.length} />}
            {state === 'success' && <SuccessState onRightClick={handleDownloadClick} />}
            {state === 'error' && <ErrorState onRightClick={handleTryAgainClick} />}
        </Modal>
    );
}
