import { List, ListItem, Typography } from '@mui/material';
import { DockColor } from '@dock/common';
import { PrimaryTextButton, SecondaryButton } from '../../Buttons';
import { ModalContainer } from './ModalContainer';
import downloadLang from '../../lang/download';

type InitialStateProps = {
    onLeftClick: () => void;
    onRightClick: () => void;
};

export function InitialState({ onLeftClick, onRightClick }: InitialStateProps) {
    return (
        <ModalContainer
            LeftButton={
                <PrimaryTextButton onClick={onLeftClick} testId="applyFilters">
                    {downloadLang.INITIAL_STATE.APPLY_FILTERS}
                </PrimaryTextButton>
            }
            RightButton={
                <SecondaryButton onClick={onRightClick} testId="proceedToExport">
                    {downloadLang.INITIAL_STATE.PROCEED_TO_EXPORT}
                </SecondaryButton>
            }
            testId="initialState"
        >
            <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: '8px' }}>
                {downloadLang.DATA_EXPORT}
            </Typography>
            <List sx={{ marginBottom: '24px' }}>
                <ListItem sx={{ padding: '0' }}>
                    <Typography variant="body1" sx={{ color: DockColor.NEUTRAL_700 }}>
                        {downloadLang.INITIAL_STATE.OPTION_1}
                    </Typography>
                </ListItem>
                <ListItem sx={{ padding: '0' }}>
                    <Typography variant="body1" sx={{ color: DockColor.NEUTRAL_700 }}>
                        {downloadLang.INITIAL_STATE.OPTION_2}
                    </Typography>
                </ListItem>
            </List>
        </ModalContainer>
    );
}
