import { download, generateCsv, mkConfig } from 'export-to-csv';
import { CSVDataFormat } from '../types.js';
import { generateFileName } from './generateFileName';

type DownloadCsvParams = {
    prefix: string;
    data: CSVDataFormat;
    date: Date;
};

export const downloadCsv = ({ data, prefix, date }: DownloadCsvParams) => {
    const csvConfig = mkConfig({
        useKeysAsHeaders: true,
        filename: generateFileName({
            prefix,
            date,
        }),
    });

    const csv = generateCsv(csvConfig)(data);

    download(csvConfig)(csv);
};
