import { Box } from '@mui/material';
import React from 'react';
import { ErrorPageBackground } from '../Icons';

type ErrorsWrapperProps = {
    children: React.ReactNode;
    shouldDisplayDockLogo?: boolean;
};

export function ErrorsWrapper({ children, shouldDisplayDockLogo = true }: ErrorsWrapperProps) {
    return (
        <Box sx={{ position: 'relative', width: '100%', height: '100vh' }}>
            <ErrorPageBackground shouldDisplayDockLogo={shouldDisplayDockLogo} />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
