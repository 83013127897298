import React from 'react';
import {
    Theme,
    Typography,
    SxProps,
    StepLabel,
    Step,
    Stepper as MuiStepper,
    Box,
} from '@mui/material';
import { StepperErrorIcon } from '../Icons';
import { StepConnectorLine } from './StepConnectorLine';

export type StepType = {
    label: string;
    isOptional: boolean;
    isError?: boolean;
    isHidden?: boolean;
};

type StepperProps = {
    steps: StepType[];
    activeStep: number;
    connector?: React.ReactElement | null;
    stepperStyles?: SxProps<Theme>;
};

const basicStepperStyles = {
    '& .MuiStepLabel-label': {
        fontSize: 'body1.fontSize',
        lineHeight: '20px',
    },
    '& .MuiStepLabel-root .Mui-completed': {
        color: 'text.primary',
    },
    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
        color: 'grey.500',
    },
    '& .MuiStepLabel-root .Mui-active': {
        color: 'text.primary',
    },
    '& .MuiStepLabel-iconContainer .Mui-active': {
        color: 'secondary.main',
    },
    '& .MuiStepLabel-iconContainer .Mui-completed': {
        color: 'secondary.main',
    },
};

export function Stepper({
    steps,
    activeStep,
    connector = <StepConnectorLine />,
    stepperStyles = {},
}: StepperProps) {
    return (
        <Box
            sx={{
                width: '100%',
                ...(!connector && {
                    '& .MuiStepper-horizontal ': {
                        justifyContent: 'space-around',
                    },
                }),
            }}
        >
            <MuiStepper activeStep={activeStep} connector={connector}>
                {steps
                    .filter(({ isHidden }) => !isHidden)
                    .map(({ isOptional, label, isError }) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isOptional) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        return (
                            <Step
                                key={label}
                                {...stepProps}
                                sx={{ ...basicStepperStyles, ...stepperStyles }}
                            >
                                <StepLabel {...labelProps} icon={isError && <StepperErrorIcon />}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
            </MuiStepper>
        </Box>
    );
}
