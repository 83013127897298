import type { SortDirection } from '@mui/material/TableCell';
import { TableSortLabel, Box, Typography } from '@mui/material';
import { Sorting, SortOrder } from '@dock/types-common';
import { visuallyHidden } from '@dock/react-mui';
import tablesLang from '../lang/tables';

export type SortLabelProps = {
    name: string;
    columnKey: string;
    sorting: Sorting;
    sortDirection: SortDirection;
    onSortClick: (property: string) => void;
};

export function SortLabel({
    name,
    columnKey,
    sortDirection,
    sorting,
    onSortClick,
}: SortLabelProps) {
    const isActive = sorting?.field === columnKey || false;
    const testId = isActive
        ? `${columnKey}Active${sortDirection}`
        : `${columnKey}Inactive${sortDirection}`;

    return (
        <TableSortLabel
            data-testid={testId}
            active={isActive}
            direction={sortDirection || 'asc'}
            onClick={() => onSortClick(columnKey)}
        >
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                {name}
            </Typography>
            {isActive ? (
                <Box component="span" sx={visuallyHidden}>
                    {sorting?.direction === SortOrder.DESC
                        ? tablesLang.SORT_DESCENDING
                        : tablesLang.SORT_ASCENDING}
                </Box>
            ) : null}
        </TableSortLabel>
    );
}
