import React from 'react';
import { AlertColor } from '@mui/material';
import { NotificationType, useProvideAlertNotifications } from '@dock/react-hooks';
import { Alert } from '../Alerts';
import { alertNotificationsContext } from './alertNotifacationsContext';

type ProvideAuthProps = {
    children: React.ReactNode;
};

const alertColorsMap: Record<NotificationType, AlertColor> = {
    [NotificationType.SUCCESS]: 'success',
    [NotificationType.ERROR]: 'error',
};

export function ProvideAlertNotifications({ children }: ProvideAuthProps) {
    const alertNotifications = useProvideAlertNotifications();
    const { notification, isOpen, closeNotification } = alertNotifications;

    const severity = (notification?.type && alertColorsMap[notification.type]) || 'success';

    return (
        <alertNotificationsContext.Provider value={alertNotifications}>
            {notification && (
                <Alert
                    severity={severity}
                    isOpen={isOpen}
                    title={notification.title}
                    description={notification.description}
                    handleCloseToast={closeNotification}
                />
            )}
            {children}
        </alertNotificationsContext.Provider>
    );
}
