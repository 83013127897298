import { Box } from '@mui/material';
import { UnexpectedError } from './UnexpectedError';
import { isError400, isError403, isError404 } from './predicates';
import { MessageTemplate } from './MessageTemplate';
import tablesLang from '../../lang/tables';
import { NotFound } from './NotFound';

type CustomErrorRowProps = {
    error: unknown;
};

export function CustomErrorRow({ error }: CustomErrorRowProps) {
    let errorMessage;
    switch (true) {
        case isError404(error):
            errorMessage = <NotFound />;
            break;
        case isError400(error):
            errorMessage = (
                <MessageTemplate
                    title={tablesLang.ERROR.BAD_REQUEST}
                    description={tablesLang.ERROR.TRY_SEARCH_AGAIN}
                    testId="badRequest"
                />
            );
            break;
        case isError403(error):
            errorMessage = (
                <MessageTemplate
                    title={tablesLang.ERROR.ACCESS_FORBIDDEN}
                    description={tablesLang.ERROR.CONTACT_ADMIN_FOR_PERMISSIONS}
                    testId="forbidden"
                />
            );
            break;
        default:
            errorMessage = <UnexpectedError />;
            break;
    }

    return (
        <Box
            sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '450px',
            }}
        >
            {errorMessage}
        </Box>
    );
}
