import { SxProps, Typography, TypographyVariant } from '@mui/material';
import { TypographyWithTooltip } from '../Typography/TypographyWithTooltip';

interface TextWithCopyIconContentProps {
    ellipsis?: 'start' | 'end';
    text: string;
    testId?: string;
    variant?: TypographyVariant;
    styles?: SxProps;
}

const CONTENT_TEST_ID = 'contentCopyText';

export function TextWithCopyIconContent({
    ellipsis,
    text,
    testId = CONTENT_TEST_ID,
    variant = 'body1',
    styles = {},
}: TextWithCopyIconContentProps) {
    if (ellipsis) {
        return (
            <TypographyWithTooltip
                variant={variant}
                text={text}
                testId={testId}
                ellipsis={ellipsis}
            />
        );
    }
    return (
        <Typography sx={styles} variant={variant} data-testid={testId}>
            {text}
        </Typography>
    );
}
