import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ListItemButton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import commonLang from '../lang/common';

type Props = {
    onClick?: () => void;
    testId?: string;
};

export function EditItem({ onClick, testId = 'editItem' }: Props) {
    return (
        <ListItem onClick={onClick} disablePadding data-testid={testId}>
            <ListItemButton>
                <ListItemIcon>
                    <BorderColorOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={commonLang.EDIT} />
            </ListItemButton>
        </ListItem>
    );
}
