import { TableRow, TableCell, Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type OneCellInRowProps = {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
    testId?: string;
};

export function OneCellInRow({ children, sx, testId }: OneCellInRowProps) {
    return (
        <TableRow data-testid={testId}>
            <TableCell colSpan={12}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        ...sx,
                    }}
                >
                    {children}
                </Box>
            </TableCell>
        </TableRow>
    );
}
