import { Box, CircularProgress } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { PrimaryTextButton } from './PrimaryTextButton';
import { SecondaryTextButton } from './SecondaryTextButton';
import { SecondaryButton } from './SecondaryButton';
import commonLang from '../lang/common';

type Props = {
    submitButtonText: string;
    onCancelHandler?: () => void;
    onBackHandler?: () => void;
    onClick?: () => void;
    isSubmitDisabled?: boolean;
    isLoading?: boolean;
    backButtonText?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    secondaryLightTestId?: string;
};

const getWrapperStyles = (onBackHandler?: () => void) => ({
    display: 'flex',
    width: '100%',
    justifyContent: onBackHandler ? 'space-between' : 'flex-end',
    gap: '8px',
});

export function FormActionButtons({
    onCancelHandler,
    onBackHandler,
    isSubmitDisabled = false,
    isLoading = false,
    submitButtonText,
    type = 'submit',
    onClick = () => {},
    backButtonText = commonLang.PREVIOUS_STEP,
    secondaryLightTestId = 'submitButton',
}: Props) {
    return (
        <Box sx={getWrapperStyles(onBackHandler)}>
            {onBackHandler && (
                <PrimaryTextButton
                    onClick={onBackHandler}
                    isDisabled={isLoading}
                    testId="backButton"
                >
                    <KeyboardBackspaceIcon fontSize="small" sx={{ mr: '4px' }} />
                    {backButtonText}
                </PrimaryTextButton>
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '8px',
                }}
            >
                {onCancelHandler && (
                    <SecondaryTextButton
                        testId="cancelButton"
                        onClick={onCancelHandler}
                        isDisabled={isLoading}
                    >
                        {commonLang.CANCEL}
                    </SecondaryTextButton>
                )}
                <SecondaryButton
                    type={type}
                    onClick={onClick}
                    testId={secondaryLightTestId}
                    isLoading={isLoading}
                    isDisabled={isSubmitDisabled || isLoading}
                    endIcon={
                        <CircularProgress
                            data-testid="loadingIcon"
                            color="inherit"
                            size="1.25rem"
                        />
                    }
                >
                    {submitButtonText}
                </SecondaryButton>
            </Box>
        </Box>
    );
}
