import { useState, useRef, useCallback, useLayoutEffect } from 'react';

const getDistanceToBottom = (bottom: number) => Math.round((bottom / 100) * 10);

export const useTableInfiniteScroll = <Element extends HTMLElement>(
    isLoading?: boolean,
    scrollingCallback?: () => void
) => {
    const [distanceBottom, setDistanceBottom] = useState(0);
    const tableEl = useRef<Element | null>(null);

    const scrollListener = useCallback(() => {
        if (!tableEl || !tableEl?.current || isLoading) {
            return;
        }

        const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;

        if (!distanceBottom) {
            setDistanceBottom(getDistanceToBottom(bottom));
        }

        const shouldInvokeCallback =
            tableEl.current.scrollTop > bottom - distanceBottom && scrollingCallback;

        if (shouldInvokeCallback) {
            scrollingCallback();
        }
    }, [distanceBottom, isLoading, scrollingCallback]);

    useLayoutEffect(() => {
        const tableRef = tableEl.current;
        tableRef?.addEventListener('scroll', scrollListener);
        return () => {
            tableRef?.removeEventListener('scroll', scrollListener);
        };
    }, [scrollListener]);

    return [tableEl];
};
