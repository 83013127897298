import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectProps,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { DockColor } from '@dock/common';
import { HelperText } from '../Input/HelperText';

export type OptionType<TValue extends string> = {
    label: string;
    description?: string;
    value: TValue;
    isDisabled?: boolean;
    isHidden?: boolean;
};

type SingleSelectProps<T extends string> = {
    label: string;
    defaultOption?: T | undefined;
    options: OptionType<T>[];
    id: string;
    onChange: (value: T) => void;
    inputProps?: SelectProps;
    testId?: string;
    helperText?: string | undefined;
    isError?: boolean;
    disabled?: boolean;
    errorMessage?: string | undefined;
};

export function SingleSelect<T extends string>({
    defaultOption,
    label,
    options,
    id,
    onChange,
    inputProps,
    testId = 'singleSelect',
    helperText,
    isError,
    errorMessage,
    disabled = false,
}: SingleSelectProps<T>) {
    const [value, setValue] = useState<T | undefined>(defaultOption);

    const onChangeHandler = (event: SelectChangeEvent<unknown>) => {
        onChange(event.target.value as T);
        setValue(event.target.value as T);
    };

    const labelId = `${id}-label`;

    const helperTextContent = errorMessage || helperText;

    return (
        <FormControl fullWidth error={!!isError}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}
                label={label}
                onChange={onChangeHandler}
                data-testid={testId}
                inputProps={{ 'data-testid': `${testId}Input` }}
                sx={{
                    '& .MuiInputBase-input': {
                        color: ({ palette }) => palette.grey[600],
                    },
                }}
                disabled={disabled}
                {...inputProps}
            >
                {options
                    .filter(({ isHidden = false }) => !isHidden)
                    .map(
                        ({
                            label: optionLabel,
                            value: optionValue,
                            isDisabled = false,
                            description,
                        }) => (
                            <MenuItem
                                disabled={isDisabled}
                                sx={{
                                    height: '55px',
                                    display: description ? 'block' : 'flex',
                                }}
                                key={optionLabel}
                                value={optionValue}
                                data-testid={`${optionValue}Option`}
                            >
                                {optionLabel}
                                {description && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            marginTop: '4px',
                                            color: DockColor.NEUTRAL_600,
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    >
                                        {description}
                                    </Typography>
                                )}
                            </MenuItem>
                        )
                    )}
            </Select>
            {helperTextContent && (
                <HelperText
                    message={helperTextContent}
                    type={errorMessage ? 'error' : 'info'}
                    sx={{ marginTop: '8px' }}
                />
            )}
        </FormControl>
    );
}
