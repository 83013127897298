import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { StepType, Stepper } from '../Stepper';
import { Modal } from './Modal';

type Props = {
    title: string;
    activeStep: number;
    isOpen: boolean;
    handleClose: () => void;
    children: ReactNode;
    steps: StepType[];
    testId: string;
};

export function ActionModalWrapper({
    children,
    isOpen,
    title,
    testId,
    activeStep,
    steps,
    handleClose,
}: Props) {
    return (
        <Modal
            handleClose={handleClose}
            isOpen={isOpen}
            title={title}
            modalAriaTitle={title}
            testId={testId}
            modalStyles={(theme) => ({
                width: '550px',
                [theme.breakpoints.up('xl')]: {
                    width: '630px',
                },
                [theme.breakpoints.up('xxl')]: {
                    width: '730px',
                },
            })}
        >
            {!!steps.length && (
                <Box sx={{ marginBottom: '24px' }}>
                    <Stepper activeStep={activeStep} steps={steps} />
                </Box>
            )}

            {children}
        </Modal>
    );
}
