import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import commonLang from '../lang/common';

export function ViewDetailsLink() {
    return (
        <ListItem disablePadding>
            <ListItemButton>
                <ListItemIcon>
                    <EastIcon />
                </ListItemIcon>
                <ListItemText primary={commonLang.VIEW_DETAILS} />
            </ListItemButton>
        </ListItem>
    );
}
