import { createContext } from 'react';
import { UseProvideNotificationsType } from '@dock/react-hooks';

const defaultValue: UseProvideNotificationsType = {
    notification: { type: null, title: '', description: '' },
    isOpen: false,
    showSuccessNotification: () => {},
    showErrorNotification: () => {},
    closeNotification: () => {},
};

export const alertNotificationsContext = createContext<UseProvideNotificationsType>(defaultValue);
