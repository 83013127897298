import Box from '@mui/material/Box';
import { DockColor } from '@dock/common';
import { LeftWireIcon } from './LeftWireIcon';
import { RightWireIcon } from './RightWireIcon';
import { DockLogoIcon } from './DockLogoIcon';

type ErrorPageBackgroundProps = { shouldDisplayDockLogo?: boolean };

export function ErrorPageBackground({ shouldDisplayDockLogo = true }: ErrorPageBackgroundProps) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}
        >
            {shouldDisplayDockLogo && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: '64px',
                        top: '64px',
                    }}
                >
                    <DockLogoIcon color={DockColor.PRIMARY_500} />
                </Box>
            )}
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'flex',
                    },
                    position: 'absolute',
                    left: '0',
                    top: '50%',
                }}
            >
                <LeftWireIcon />
            </Box>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'flex',
                    },
                    position: 'absolute',
                    right: '0',
                    top: '30%',
                }}
            >
                <RightWireIcon />
            </Box>
        </Box>
    );
}
