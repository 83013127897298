import { useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { useScreen } from './useScreen';
import { useBoolean } from './useBoolean';

const SCREEN_SIZE = 1440;
const shouldSidebarOpen = (width: number) => width >= SCREEN_SIZE;

export function useSidebar() {
    const screen = useScreen();
    const { value, setValue } = useLocalStorage<boolean>('sidebar');
    const initialValue = value instanceof Error ? shouldSidebarOpen(screen.width || 0) : value;

    const [isOpen, toggle, setIsOpen] = useBoolean(Boolean(initialValue));

    useEffect(() => {
        setValue(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!(value instanceof Error)) {
            setIsOpen(Boolean(value));
        }
    }, [value]);

    return { isOpen, setIsOpen, toggle };
}
