import React from 'react';
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    SxProps,
    Theme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TypographyWithTooltip } from '../Typography/TypographyWithTooltip';

type AccordionProps = {
    title: string;
    helpText?: string;
    children: React.ReactNode;
    disabled?: boolean;
    isWithIcon?: boolean;
    styles?: SxProps<Theme>;
    childStyles?: SxProps<Theme>;
    testId?: string;
};

export function Accordion({
    children,
    title,
    helpText,
    styles = {},
    childStyles = {},
    isWithIcon = false,
    disabled = false,
    testId = '',
}: AccordionProps) {
    return (
        <MuiAccordion
            disabled={disabled}
            disableGutters
            sx={{
                '.MuiAccordionSummary-root': (theme) => ({
                    backgroundColor: theme.palette.background.default,

                    [theme.breakpoints.up('xl')]: {
                        py: 0.5,
                    },
                    [theme.breakpoints.up('xxl')]: {
                        py: 1,
                    },
                }),
                '.MuiAccordionDetails-root': {
                    padding: '16px 24px',
                },
                ...styles,
            }}
            data-testid={testId}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3" sx={{ marginRight: 1 }}>
                    {title}
                </Typography>
                <TypographyWithTooltip
                    text={helpText ?? ''}
                    variant="body1"
                    isWithIcon={isWithIcon}
                />
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    backgroundColor: ({ palette }) => palette.grey[300],
                    ...childStyles,
                }}
            >
                {children}
            </AccordionDetails>
        </MuiAccordion>
    );
}
