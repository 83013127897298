import { AlertTitle, Box, Typography } from '@mui/material';

type ContentWithTitleProps = {
    title: string;
    description: string;
};

export function ContentWithTitle({ title, description }: ContentWithTitleProps) {
    return (
        <>
            <AlertTitle>{title}</AlertTitle>
            <Box sx={{ overflowWrap: 'break-word' }}>
                <Typography variant="body1" data-testid="alertDescription">
                    {description}
                </Typography>
            </Box>
        </>
    );
}
