import React from 'react';
import { Button } from '@mui/material';

type PrimaryTextButtonProps = {
    children: React.ReactNode;
    onClick: () => void;
    isDisabled?: boolean;
    testId?: string;
};

export function PrimaryTextButton({
    children,
    onClick,
    isDisabled = false,
    testId = 'primaryTextButton',
}: PrimaryTextButtonProps) {
    return (
        <Button
            variant="text"
            onClick={onClick}
            disabled={isDisabled}
            data-testid={testId}
            sx={{
                color: 'primary.main',
                px: 3,
                py: 1,
            }}
        >
            {children}
        </Button>
    );
}
