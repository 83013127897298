import { Box, Typography } from '@mui/material';
import tablesLang from '../../lang/tables';
import { SecondaryButton } from '../../Buttons';

export function UnexpectedError() {
    return (
        <Box data-testid="unexpectedError">
            <Typography sx={{ mb: '2px' }}>{tablesLang.ERROR.UNEXPECTED_ERROR_TABLE}</Typography>
            <SecondaryButton
                onClick={() => window.location.reload()}
                sx={{ width: '100px', marginX: 'auto', marginY: '1em' }}
            >
                {tablesLang.ERROR.RELOAD}
            </SecondaryButton>
        </Box>
    );
}
