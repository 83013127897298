import { Control, Controller, useFormContext } from 'react-hook-form';
import { SelectProps } from '@mui/material';
import { OptionType, SingleSelect } from './SingleSelect';

type ControlledSingleSelectProps<T extends string> = {
    label: string;
    defaultOption?: T;
    options: OptionType<T>[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any, any>;
    name: string;
    testId?: string;
    helperText?: string | undefined;
    disabled?: boolean;
    inputProps?: SelectProps;
};

export function ControlledSingleSelect<T extends string>({
    defaultOption,
    label,
    options,
    control,
    name,
    testId = 'controlledSingleSelect',
    helperText,
    disabled = false,
    inputProps,
}: ControlledSingleSelectProps<T>) {
    const { setValue } = useFormContext();

    const onChangeHandler = (value: string) => {
        setValue(name, value);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <SingleSelect
                    {...rest}
                    disabled={disabled}
                    defaultOption={defaultOption ?? ''}
                    id={name}
                    label={label}
                    options={options}
                    inputProps={{
                        variant: 'outlined',
                        inputRef: ref,
                        ...inputProps,
                    }}
                    onChange={onChangeHandler}
                    testId={testId}
                    helperText={helperText}
                    isError={!!error}
                    errorMessage={error?.message}
                />
            )}
        />
    );
}
