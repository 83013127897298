export function CardChip() {
    return (
        <svg
            width="46"
            height="37"
            viewBox="0 0 46 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect opacity="0.5" width="45.5326" height="36.6354" rx="4.1869" fill="white" />
            <path
                d="M9.20766 4.70366C9.20766 4.70366 15.3461 2.17015 15.3461 8.32242C15.3461 14.4747 8.52561 12.3031 8.52561 12.3031M8.52561 12.3031H0M8.52561 12.3031V21.35M8.52561 21.35H0M8.52561 21.35C8.52561 21.35 17.3922 21.35 16.7102 27.14C16.0281 32.93 10.9128 31.8444 10.9128 31.8444"
                stroke="white"
                strokeWidth="2.09345"
            />
            <path
                d="M36.0226 29.2786C36.0226 29.2786 29.7038 31.9069 29.5976 25.8016C29.4915 19.6963 36.5984 21.7245 36.5984 21.7245M36.5984 21.7245L45.4351 21.5659M36.5984 21.7245L36.4423 12.7466M36.4423 12.7466L45.279 12.588M36.4423 12.7466C36.4423 12.7466 27.252 12.9116 27.859 7.15304C28.4661 1.39451 33.7869 2.3767 33.7869 2.3767"
                stroke="white"
                strokeWidth="2.09345"
            />
        </svg>
    );
}
