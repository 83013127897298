import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { HeadlessTable } from '../SimpleTable';

type Props = {
    title: string;
    children: ReactNode;
};

export function SummarySection({ title, children }: Props) {
    return (
        <Stack sx={{ marginBottom: '24px' }}>
            <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                {title}
            </Typography>
            <HeadlessTable
                tableBodySx={{
                    borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
                }}
            >
                {children}
            </HeadlessTable>
        </Stack>
    );
}
