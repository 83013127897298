import { Badge } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { SecondaryButton } from '../Buttons';
// TODO: texts should be move to package level
import filtersLang from './lang';

type ButtonHandler = {
    onClick: (b: boolean) => void;
    isDisabled?: boolean | undefined;
    isFilterOn?: boolean | undefined;
    testId?: string;
};

// TODO: replace with Secondary Button
export function FilterButton({
    onClick,
    isDisabled = false,
    isFilterOn = false,
    testId = 'filterButton',
}: ButtonHandler) {
    return (
        <SecondaryButton
            onClick={() => onClick(true)}
            isDisabled={isDisabled}
            disableElevation
            testId={testId}
            sx={{ maxHeight: '40px', margin: 'auto 0' }}
            startIcon={
                <Badge
                    color="primary"
                    variant="dot"
                    invisible={!isFilterOn}
                    data-testid="filterActiveIcon"
                >
                    <TuneIcon />
                </Badge>
            }
        >
            {filtersLang.FILTER}
        </SecondaryButton>
    );
}
