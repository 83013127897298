import { MessageTemplate } from './MessageTemplate';
import tablesLang from '../../lang/tables';

type Props = {
    testId?: string;
};

export function NotFound({ testId = 'notFound' }: Props) {
    return (
        <MessageTemplate
            title={tablesLang.ERROR.RESULT_FOUND}
            description={tablesLang.ERROR.TRY_SEARCH_AGAIN}
            testId={testId}
        />
    );
}
