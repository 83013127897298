import { DockColor } from '@dock/common';
import { Box, SxProps, Typography } from '@mui/material';
import { CardChip } from './CardChip';

interface CardProps {
    cardHolder: string;
    cardNumber: string;
    expirationDate: string;
}

const wrapperStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: DockColor.WHITE,
    width: '100%',
    maxWidth: '340px',
    height: '173px',
    borderRadius: 4,
    paddingLeft: 3,
    py: 3,
};

const cardChipWrapperStyles: SxProps = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 3,
    pb: 2,
};

export function Card({ cardNumber, expirationDate, cardHolder }: CardProps) {
    const hashes = '*'.repeat(4);
    const hashedCardNumber = `${hashes} ${hashes} ${hashes} ${cardNumber}`;

    return (
        <Box
            sx={({ breakpoints, palette }) => ({
                ...wrapperStyles,
                [breakpoints.up('xl')]: {
                    maxWidth: '376px',
                },
                background: `radial-gradient(90.26% 389.51% at 93.41% 22.62%, ${palette.secondary.main} 0%, ${palette.secondary.dark} 100%)`,
            })}
        >
            <Box sx={cardChipWrapperStyles}>
                <CardChip />
            </Box>

            <Typography>{cardHolder}</Typography>
            <Typography variant="h2">{hashedCardNumber}</Typography>
            <Typography>
                EXP <span>{expirationDate}</span>
            </Typography>
        </Box>
    );
}
