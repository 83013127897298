import { IconButton, Stack } from '@mui/material';
import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import lang from './lang';

type CollapsableTitleProps = {
    handleCollapsableElement: () => void;
    isOpen: boolean;
    titleElement: React.ReactNode;
};

export function CollapsableTitle({
    isOpen,
    titleElement,
    handleCollapsableElement,
}: CollapsableTitleProps) {
    return (
        <Stack
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                cursor: 'pointer',
                padding: '16px',
                borderBottom: (theme) => `2px solid ${theme.palette.grey[200]}`,
            }}
            onClick={handleCollapsableElement}
        >
            {titleElement}
            <IconButton aria-label={lang.ARIA_LABEL_COLLAPSABLE_TILE} size="small">
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </Stack>
    );
}
