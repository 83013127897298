import React from 'react';
import { NumericFormat, InputAttributes, NumberFormatBaseProps } from 'react-number-format';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormHelperText, InputAdornment } from '@mui/material';

type CustomProps = {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
};

export const NumberFormatCustom = React.forwardRef<
    NumberFormatBaseProps<InputAttributes>,
    CustomProps
>((props, ref) => {
    const { onChange } = props;
    return (
        <NumericFormat
            {...{ ...props, ...{ onChange: undefined } }}
            getInputRef={ref}
            onValueChange={({ value }: { value: string }) => {
                onChange({
                    target: {
                        name: props.name,
                        value,
                    },
                });
            }}
            thousandSeparator
        />
    );
});

interface InputRangeProps {
    min: string;
    helperText?: string;
    minHelpText?: string;
    max: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function InputRange({ min, max, handleChange, minHelpText, helperText }: InputRangeProps) {
    const isError = !!(max && min && Number(max) < Number(min));
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: '24px',
                }}
            >
                <TextField
                    label="From"
                    value={min}
                    onChange={handleChange}
                    name="minAmount"
                    placeholder="-100.00"
                    id="formatted-minAmount-input"
                    inputProps={{ 'data-testid': 'minAmountInput' }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                        // TODO: consider to change any to specific type
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputComponent: NumberFormatCustom as any,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                    error={isError}
                    variant="outlined"
                    helperText={minHelpText}
                />
                <TextField
                    label="To"
                    error={isError}
                    value={max}
                    onChange={handleChange}
                    placeholder="100.00"
                    name="maxAmount"
                    id="formatted-maxAmount-input"
                    inputProps={{ 'data-testid': 'maxAmountInput' }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                        // TODO: consider to change any to specific type
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputComponent: NumberFormatCustom as any,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                    variant="outlined"
                />
            </Box>
            {isError && (
                <FormHelperText sx={{ color: ({ palette }) => palette.error.main }}>
                    {helperText}
                </FormHelperText>
            )}
        </>
    );
}
