export function ErrorSvg() {
    return (
        <svg
            width="88"
            height="88"
            viewBox="0 0 88 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="44" cy="44" r="44" fill="#F8374A" />
            <path d="M61 28L28 61" stroke="white" strokeWidth="5" />
            <path d="M28 28L61 61" stroke="white" strokeWidth="5" />
        </svg>
    );
}
