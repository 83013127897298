import { DockColor, isNumberPositive, stringToThousand } from '@dock/common';
import { TablesTypography } from './TablesTypography';

type Props = {
    amount: string | undefined | null;
    currency?: string | undefined | null;
};

export function ColoredAmountText({ amount, currency }: Props) {
    const isPositive = isNumberPositive(amount);
    const currencyValue = currency ? ` ${currency}` : '';
    const value = amount ? `${stringToThousand(amount)}${currencyValue}` : '';
    const styles = {
        ...(isPositive && {
            color: DockColor.SYSTEM_POSITIVE,
            textShadow: '0px 0.5px 1px rgba(0,102,14,0.3)',
        }),
    };

    return <TablesTypography content={value} styles={styles} />;
}
