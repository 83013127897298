import { useState } from 'react';

export const useBoolean = (state: boolean): [boolean, () => void, (b: boolean) => void] => {
    const [isOpen, setIsOpen] = useState<boolean>(state);

    const flipState = () => {
        setIsOpen((prevState) => !prevState);
    };

    return [isOpen, flipState, setIsOpen];
};
