import { NavLink } from '../ActionsMenu';
import { CommonButton } from './CommonButton';

type CommonButtonAsLinkProps = {
    label: string;
    to: string;
    testId?: string;
    icon?: React.ReactNode;
};

export function CommonLinkAsButton({
    label,
    to,
    testId = 'commonLinkAsButton',
    icon,
}: CommonButtonAsLinkProps) {
    return (
        <NavLink
            label={<CommonButton onClick={() => {}} label={label} testId={testId} icon={icon} />}
            to={to}
        />
    );
}
