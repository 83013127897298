import { StatusCodes } from '@dock/common';

export function hasCode(error: unknown): error is { code: number } {
    return typeof error === 'object' && error !== null && 'code' in error;
}

function hasStatus(error: unknown): error is { status: string } {
    return typeof error === 'object' && error !== null && 'status' in error;
}

const isError = <T>(error: T, code: StatusCodes): boolean =>
    hasStatus(error) && error.status === StatusCodes[code];

export const isError400 = <T>(error: T): boolean => isError(error, 400);

export const isError404 = <T>(error: T): boolean => isError(error, 404);

export const isError403 = <T>(error: T): boolean => isError(error, 403);
