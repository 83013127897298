import { SimpleRow } from './SimpleRow';
import { ColoredAmountText } from './ColoredAmountText';

type Props = {
    label: string;
    amount: string | undefined | null;
    currency?: string | undefined | null;
    valueTestId?: string;
};

export function AmountSimpleRow({ label, amount, currency, valueTestId }: Props) {
    return (
        <SimpleRow
            label={label}
            value={<ColoredAmountText amount={amount} currency={currency} />}
            valueTestId={valueTestId}
        />
    );
}
