import {
    TableCell,
    SortDirection,
    SxProps,
    Box,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import type { Sorting } from '@dock/types-common';
import { SortLabel } from './SortLabel';
import { TypographyWithTooltip } from '../Typography/TypographyWithTooltip';

export enum ColumnAlign {
    LEFT = 'left',
    RIGHT = 'right',
}

export type Column = {
    name: string;
    key: string;
    sortable?: boolean;
    align?: ColumnAlign;
    tooltip?: string;
};

export type TableHeaderProps = {
    columns: Column[];
    thStyles?: SxProps;
    onSortClick?: ((property: string) => void) | undefined;
    sorting?: Sorting | undefined;
};

export function TableHeader({ columns, thStyles = {}, onSortClick, sorting }: TableHeaderProps) {
    return (
        <TableHead
            sx={{
                '.MuiTableCell-stickyHeader': {
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[500]
                            : theme.palette.grey[900],
                },
                ...thStyles,
            }}
        >
            <TableRow>
                {columns.map(({ key, name, sortable, align, tooltip }) => {
                    const sortDirection: SortDirection =
                        sorting?.field === key && !!sortable ? sorting.direction : false;
                    const shouldShowSortLabel = !!sortable && !!sorting && !!onSortClick;

                    return (
                        <TableCell
                            sx={(theme) => ({
                                color: theme.palette.grey[600],
                            })}
                            align={align || ColumnAlign.LEFT}
                            key={key}
                            sortDirection={sortDirection}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: align || ColumnAlign.LEFT,
                                }}
                            >
                                {shouldShowSortLabel ? (
                                    <SortLabel
                                        name={name}
                                        columnKey={key}
                                        sorting={sorting}
                                        sortDirection={sortDirection}
                                        onSortClick={onSortClick}
                                    />
                                ) : (
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                        {name}
                                    </Typography>
                                )}
                                {!!tooltip && (
                                    <TypographyWithTooltip
                                        text={tooltip}
                                        variant="body2"
                                        isWithIcon
                                    />
                                )}
                            </Box>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
