import type { Dispatch, SetStateAction } from 'react';

const falseDateInputLenght = '2023-02-1'.length;

export const evaluateDateAfterDatePickerInput =
    (isValid: (date: Date | null) => boolean) =>
    (dateUpdate: Dispatch<SetStateAction<Date>>) =>
    (date: Date | null, dateString?: string) => {
        if (
            date !== null &&
            !!dateString &&
            dateString.length > falseDateInputLenght &&
            isValid(date)
        ) {
            //* keyword input
            dateUpdate(date);
        } else if (date !== null && !dateString && isValid(date)) {
            //* dropdown input
            dateUpdate(date);
            // } else if (date === null) {
            //* reset input
            //     dateUpdate(null);
        }
    };
