import { Typography } from '@mui/material';
import React from 'react';
import lang from '../lang/errors';
import { ErrorsWrapper } from './ErrorsWrapper';

type NotFoundBaseWrapperProps = {
    children?: React.ReactNode;
    shouldDisplayDockLogo?: boolean;
};

export function NotFoundBaseWrapper({
    children,
    shouldDisplayDockLogo = true,
}: NotFoundBaseWrapperProps) {
    return (
        <ErrorsWrapper shouldDisplayDockLogo={shouldDisplayDockLogo}>
            <Typography
                component="h1"
                variant="inherit"
                sx={{
                    fontSize: '211px',
                    lineHeight: '289px',
                    color: 'primary.main',
                    fontWeight: 'bold',
                }}
            >
                {lang.ERROR_CODE}
            </Typography>

            <Typography variant="h1" component="h2" sx={{ mb: 1 }}>
                {lang.OOPS}
            </Typography>
            <Typography variant="h3" component="h3" sx={{ mb: 2, maxWidth: '525px' }}>
                {lang.PAGE_NOT_FOUND}
            </Typography>
            {children}
        </ErrorsWrapper>
    );
}
