import { ellipsisText, ellipsisTextStart } from '@dock/react-mui';
import { Typography, TypographyVariant } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SimpleTooltip } from '../../Tooltip';

type TypographyWithTooltipProps = {
    text: string;
    variant?: TypographyVariant;
    testId?: string;
    isWithIcon?: boolean;
    ellipsis?: 'start' | 'end';
};

export function TypographyWithTooltip({
    text,
    testId,
    variant = 'body2',
    ellipsis,
    isWithIcon = false,
}: TypographyWithTooltipProps) {
    const ellipsesStyles = ellipsis === 'end' ? ellipsisText : ellipsisTextStart;

    return (
        <SimpleTooltip title={text}>
            {isWithIcon ? (
                <InfoOutlinedIcon
                    sx={(theme) => ({
                        color: 'primary.dark',
                        width: '16px',
                        height: '16px',

                        [theme.breakpoints.up('xl')]: {
                            width: '20px',
                            height: '20px',
                        },
                        [theme.breakpoints.up('xxl')]: {
                            width: '24px',
                            height: '24px',
                        },
                    })}
                />
            ) : (
                <Typography variant={variant} data-testid={testId} sx={ellipsesStyles}>
                    {text}
                </Typography>
            )}
        </SimpleTooltip>
    );
}
