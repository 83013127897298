import React from 'react';
import Box from '@mui/material/Box';
import { useHover } from '@dock/react-hooks';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useCopyButton } from './useCopyButton';
import { CopyButton } from './CopyButton';

export type TextWithCopyIconCellProps = {
    text: string;
    children: React.ReactElement;
};

export function TextWithCopyIcon({ children, text }: TextWithCopyIconCellProps) {
    const { isCopied, copyHandler } = useCopyButton();
    const [ref, isHover] = useHover<HTMLElement>();

    return (
        <Box sx={{ display: 'flex' }} ref={ref} data-testid="textWithCopyIcon">
            {children}
            <div style={{ position: 'relative', paddingRight: '30px' }}>
                {isHover && !isCopied && <CopyButton text={text} onCopy={copyHandler} />}
                {isCopied && (
                    <div style={{ position: 'absolute', padding: '0 8px' }}>
                        <DoneAllIcon data-testid="doneAllIcon" fontSize="small" color="success" />
                    </div>
                )}
            </div>
        </Box>
    );
}
