import { useState, useEffect } from 'react';
import { safeParseJson } from '@dock/common';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';

type UseLocalStorageReturn<T> = {
    value: T | undefined;
    setValue: (value: T) => void;
    removeValue: () => void;
};

const isValueFromStorageChanged = (event: StorageEvent): boolean =>
    event.newValue !== event.oldValue;

const isKeysEqual = (event: StorageEvent, currentKey: string): boolean => event.key === currentKey;

export function useLocalStorage<T>(key: string): UseLocalStorageReturn<T | Error> {
    const [storedValue, setStoredValue] = useState<T | Error | undefined>(() => {
        const item = window.localStorage.getItem(key);
        const parsedValue = safeParseJson<T>(item || '');
        if (E.isRight(parsedValue)) {
            return parsedValue.right;
        }

        return parsedValue.left;
    });

    const removeValue = () => {
        setStoredValue(undefined);
        window.localStorage.removeItem(key);
    };

    const setValueToStorage = (value: T | Error) => {
        if (value instanceof Error) {
            removeValue();
            return;
        }
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    const setValue = (value: T | Error) => {
        if (value instanceof Error) {
            removeValue();
            return;
        }
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        if (valueToStore !== storedValue) setValueToStorage(valueToStore);
    };

    useEffect(() => {
        const handleStorage = (ev: StorageEvent) => {
            if (isKeysEqual(ev, key) && isValueFromStorageChanged(ev)) {
                pipe(
                    safeParseJson<T>(ev.newValue ?? ''),
                    E.map((data) => setStoredValue(data)),
                    E.mapLeft((error) => setStoredValue(error))
                );
            }
        };

        window.addEventListener('storage', (ev) => handleStorage(ev));

        return () => window.removeEventListener('storage', handleStorage);
    }, [key, setStoredValue]);

    return { value: storedValue, setValue, removeValue };
}
